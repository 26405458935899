body {
    width: 1200px;
    background-color: #cccccc;
    margin: 0 auto !important;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    font-family: Arial, Helvetica, sans-serif;
}
h1 {
    text-align: center;
    position: relative;
}
.slider_landscape {
    position: relative;
    margin: auto;
    width: 1200px;
}
.slider_portrait {
    position: relative;
    margin: auto;
    width: 800px;
}
.img_landscape {
    width:1200px;
}
.img_portrait {
    width:800px;
}